export class UserAccount {
  private readonly username: string;
  private readonly name: string;
  /** initialize to empty array then `AuthService.authorize()` will populate
   * accordingly */
  private readonly roles: string[];
  private readonly userId: string;

  constructor(username: string, name: string, userId: string) {
    this.username = username;
    this.name = name;
    this.userId = userId;
    this.roles = [];
  }

  getUsername() {
    return this.username;
  }

  getName() {
    return this.name;
  }

  getRoles() {
    return this.roles;
  }

  getUserId() {
    return this.userId;
  }

  addRoles(newRoles: string[]) {
    this.roles.push(...newRoles);
  }
}
