import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ColumnChooser } from '../column-chooser/ColumnChooser';
import { Icon, IconNames } from "../../icons";
import { useTable, usePagination, useGlobalFilter, useFilters, useSortBy, useRowSelect, useExpanded, Row } from "react-table";
import { HTMLTable, IHTMLTableProps } from '@blueprintjs/core';
import { LoadingOverlay } from '../loading/LoadingOverlay';
import { useDebouncedState } from './../../hooks';
import { Pagination } from './Pagination';
import { TBody } from './TBody';
import { THead } from './THead';
import { STRINGS } from '../../strings';
import { isEqual } from 'lodash';

import './Table.scss';
import { ColumnFilterControlProps, DefaultColumnFilterControl } from './TableBuiltinColumnFilters';

export type TableFilter = {
    column: string, //column name
    value: any // filter value
}

/** An enum of React Table's in-built sort function names */
export enum SortFunctions {
    "string" = "string",
    "number" = "number",
    "basic" = "basic",
    "datetime" = "datetime",
    "alphanumeric" = "alphanumeric",
};

/** An enum of React Table's in-built filter function names */
export enum FilterFunctions {
    "fuzzyText" = "fuzzyText",
    "equals" = "equals",
    "between" = "between",
    "includes" = "includes",
};

function defaultMultiValueFilter(rows, [columnID], filterValue) {
  const escapeMetaChars = (str: string): string => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const filterPattern = Array.isArray(filterValue)
    ? filterValue
        .map((v) => v.trim())
        .filter((v) => v !== '')
        .map(escapeMetaChars)
        .join('|')
    : escapeMetaChars(filterValue);

  const filterTestRegEx = new RegExp(filterPattern, 'i');
  const filteredRows = rows.filter((row) =>
    filterTestRegEx.test(row.values[columnID] || '')
  );

  return filteredRows;
}

export type TableColumnDef = {
    Header: string | Function,
    accessor?: string | Function,
    id: string,
    headerClassName?: string,
    className?: string,
    style?: Record<string, string>,
    Cell?: Function,
    /** A formatter function for returning each cell's contents for this column.
     * (Note: Same as Cell but with easier access to the data record.
     * If provided, it will override the Cell parameter) */
    formatter?: (record) => string | JSX.Element,

    /** Boolean to control if this column is sortable or not. Maps to defaultCanSort param of React Table */
    sortable?: boolean,
    /** Should the column be storted in descending order first when sort is activated? defaults to false */
    sortDescFirst?: boolean,
    /** Invert the sort order especially when using the default sorting method. defaults to false */
    sortInverted?: boolean,
    /** How to sort this column? You can choose one of the pre-defined functions from SortFunctions enum
     * or you can provide a custom function. If a custom function is provided, it should be memoized to prevent unnecessary
     * re-renders. Maps to sortType of React Table */
    sortFunction?: SortFunctions | ((recordA, recordB, columnId: string, desc: boolean) => 1 | -1 | 0),

    /** A boolean to control if filter should be shown for this column or not */
    showFilter?: boolean,
    /** A boolean to control if filter cotrol should be shown always for this column */
    alwaysShowFilter?: boolean,
    /** Control how to filter the records. Choose one of the pre-defined functions from filterFunctions enum
     * or pass a custom function that will go through the rows and return an array of filtered rows.
     * If a function is used, it must be memoized to prevent performance issues. */
    filterFunction?: FilterFunctions | ((rows, [columnID], filterValue) => any[]),
    /** Filter control to be used (A React Component). Params for this component will include
     * `column: { filterValue, preFilteredRows, setFilter }` and any other params passed in the
     * filterProps attribute of this column's definition. When user interacts with this control,
     * the column.setFilter method passed in param can be called to set the value for this filter.
     * The table will then pass this value through the used `filter` function to get filtered list of rows */
    filterControl?: ReactNode,
    /** Set this flag as true if filter value can be an array of more than one value */
    multiValueFilter?: boolean,
    /** Placeholder text to displayed used if default filter text field is used (filterControl not provided) */
    filterPlaceHolder?: string,
    /** Additional attributes to pass to the Filter react component if necessary. */
    filterProps?: Partial<ColumnFilterControlProps>,
}

export type TableDataObject = {
    /** When enableSelection is true for table, passing this flag as false for a record will prevent the row
     * from being selectable. Defaults to true if not provided */
    selectable?: boolean,
    /** ClassName to apply at the record's table row */
    className?: string,
    /** Contents that should be displayed under the current row in an expandable format. When a subComponent is provided,
     * an expansion toggle button will automatically be displayed next to this row which when expanded will render
     * the contents in a row below it  */
    subComponent?: ReactNode,
    /** More rows that should be displayed under the current row in an expandable tree table like fashion  */
    subRows?: Array<TableDataObject>,
    [index: string]: any,
}

//todo proper types, we should expose all the inner beats with the proper typing
export type TableProps = {
    id?: string,
    className?: string,
    /** the array of columns. */
    columns: Array<TableColumnDef>,
    /** Use this object to specify defaults that will be applied for all columns unless overridden. */
    columnDefinitionDefaults?: Partial<TableColumnDef>,
    /** the array of data. */
    data?: Array<TableDataObject>,
    noDataText?: string,
    /** Passthrough config object for the underlying useTable hook */
    useTableParams?: object,
    /** Show a loading indicator using this flag */
    loading?: boolean,
    /** Callback for when a row is clicked. The event will have `id` of row and data record under `record` */
    onRowClick?: Function,

    /** Control how to filter the records. Choose one of the pre-defined functions from filterFunctions enum
     * or pass a custom function that will go through the rows and return an array of filtered rows.
     * If a function is used, it must be memoized to prevent performance issues. */
    globalFilterFunction?: FilterFunctions | ((rows, ids, globalFilterValue) => any[]),
    /** optional initial/default value for global filter (Must be memoized) */
    globalFilterValue?: any,

    /** optional initial/default value for Column-level filters (Must be memoized) */
    filterValues?: Array<TableFilter>,
    /** specifies whether or not to show the filter row. */
    showFilterRow?: boolean,
    /** A flag which can be passed as true if we want to do column-level filter in the data externally. Defaults to false.
     * For server-side column-level filtering, this can be passed as false and we can listen to onFiltersChange event and
     * update the supplied data */
    filterExternally?: boolean,
    /** Callback for when a column level filter is changed by the user */
    onFiltersChange?: (newFilters: Array<TableFilter>, oldFilters?: Array<TableFilter>) => void,

    getHeaderProps?: (column: any) => Record<string, string>,
    getRowProps?: (row: any) => Record<string, string>,
    getColumnProps?: (column: any) => Record<string, string>,
    getCellProps?: (cell: any) => Record<string, string>,
    colorScheme?: string, // TODO: This is temporary since color scheme for dark theme is different in apps consuming this library

    /** When there are columns that support sorting, this parameter controls which column to initially sort the table by when mounting */
    sortBy?: { id: string, desc?: boolean }[],
    /** A flag which can be passed as true if we want to do sorting in the data externally. Defaults to false.
     * For server-side sorting, this can be passed as false and we can listen to onSortChange event and update the supplied data */
    sortExternally?: boolean,
    /** Callback for when a sortBy change occurs */
    onSortByChange?: (newSortBy, oldSortBy) => void,

    /** Should this table support pagination? */
    enablePagination?: boolean,
    /** What should be the default page size when this table is mounted */
    defaultPageSize?: number,
    /** List of page sizes that should be supported */
    pageSizeOptions?: Array<number>,
    /** Callback for when a pagination change occurs */
    onPaginationChange?: (event: {pageIndex: number, pageSize: number}) => void,
    /** the handler for page size changes.  This handler is only notified when the user clicks on the combo box with the list of page sizes. */
    onPageSizeChange?: (pageSize: number) => void,
    /** the handler for page index changes.  This handler is only notified when the user clicks on one of the page change controls. */
    onPageIndexChange?: (pageIndex: number) => void,
    /** the handler for page count changes.  This handler is called whenever the page count changes. */
    onPageCountChange?: (pageCount: number) => void,
    /** Should pagination control be rendered and displayed to the user? (Set this to false if pagination is to be manually handled using code and/or with a custom control) */
    hidePagination?: boolean,
    /** Controls if pagination control should be shown at the top of the table */
    enableTopPagination?: boolean

    /** Should row selection be enabled and a checkbox be rendered in first column */
    enableSelection?: boolean,
    /** Should row selection be reset each time user paginates */
    resetSelectionsOnPaginate?: boolean,
    /** A limit for number of active selections. Passing 1 will change selection control from checkbox to radio button */
    maxSelectionCount?: number,
    /** If set as true, clicking on the row also will perform row selection. Keep in mind that this will also trigger
     * onRowClick handler */
    selectOnRowClick?: boolean,
    /** When enableSelction is true, this flag which defaults to true controls if the checkbox/radiobutton should be displayed */
    showSelectionControl?: boolean,
    /** Callback for when rows get selected/unselected */
    onSelectionChange?: (selectedRows:Array<object>) => void,
    /** An optional array of row IDs that can be used to set selected rows */
    selectedRows?: string[],

    /** An optional array of row IDs that can be used to pick which rows to render as expanded by default */
    expandedRows?: string[],
    /** If passed as true, the table will act like a single-expansion accordion where expanding one row will collapse any other expanded row */
    expandOnlyOneRow?: boolean,
    /** If set as true, clicking on the row also will expand/collapse the row. Keep in mind that this will also trigger onRowClick handler & row selection if enabled */
    expandOnRowClick?: boolean,
    /** Callback for when rows get expanded/collapsed. */
    onExpansionChange?: (expandedRows:Array<Row>) => void,

    /** a callback for header click events. */
    onHeaderClick?: (event: any, column: any) => void,
    /** A flag that shows a darker background for odd rows */
    striped?: boolean,
    /** When passed true, the row's spacing will be reduced to show a condensed table */
    condensed?: boolean,
    /** This flag makes the table provide a feedback when user hovers over the rows making it seem clickable */
    interactive?: boolean,
    /** Controls if border line should be displayed between each row */
    bordered?: boolean,
    /** Controls if border line should be displayed between each column */
    columnsBordered?: boolean,
    /** When passed as true, the table will have it's background color set to transparent. This can be used when
     * we want the background color from the container where table is rendered to show through */
    transparent?: boolean,
     /** Show or hide column choosers's access icon */
    showColumnChooser?: boolean,
    /** List of column ids to be shown in the table. All columns will be shown if none specified. */
    selectedColumnIds?: Array<string>,
    /** Callback for when columns selection changed */
    onColumnsChange?: (selectedColumnIds:Array<string>) => void,
    /** Add or remove overflow: auto */
    removeOverflow?: boolean,
    /** flip the table */
    flipTable?: boolean;
} & IHTMLTableProps

export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];
const EMPTY_COLUMNS = [];
const EMPTY_DATA = [];

/** Renders the table react component.
 *  @param props the properties passed in.
 *  @returns the JSX with the table component. */
export const Table = ({
        id,
        className,
        columns = EMPTY_COLUMNS,
        columnDefinitionDefaults,
        data = EMPTY_DATA,
        noDataText = STRINGS.TABLE.noData,
        loading = false,
        interactive,
        defaultPageSize,
        onPaginationChange,
        onPageSizeChange,
        onPageIndexChange,
        onPageCountChange,
        pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
        useTableParams,
        hidePagination = false,
        onClick,
        onRowClick,
        globalFilterFunction,
        globalFilterValue,
        filterValues = [],
        showFilterRow = false,
        onFiltersChange,
        filterExternally = false,
        getHeaderProps,
        getRowProps,
        getColumnProps,
        getCellProps,
        sortBy = [],
        sortExternally = false,
        onSortByChange,
        enablePagination = true, // Default enabling pagination to true
        enableTopPagination = false,
        enableSelection = false,
        resetSelectionsOnPaginate = true, // Default reset selections when paginate
        maxSelectionCount = 0,
        selectOnRowClick = true,
        showSelectionControl = true,
        onSelectionChange,
        selectedRows,
        expandedRows,
        expandOnlyOneRow = false,
        expandOnRowClick = true,
        onExpansionChange,
        onHeaderClick,
        striped = false,
        condensed = false,
        colorScheme,
        bordered = true,
        columnsBordered = false,
        transparent = false,
        showColumnChooser = false,
        selectedColumnIds = [],
        onColumnsChange,
        removeOverflow = false,
        flipTable = false,
        ...passThroughIHTMLTableProps
    }: TableProps): JSX.Element => {

    if (selectedColumnIds && selectedColumnIds.length == 0) {
        selectedColumnIds = columns.map((column) => { return column.id });
    }

    const [tableColumns, setTableColumns] = useState(columns);
    const [selectedIds, setSelectedIds] = useState(selectedColumnIds);
    // When interactive flag wasn't set explicitly, set it to true by default if either
    // onRowClick callback was provided or if the table is selectable and has selectOnRowClick enabled.
    // Default it to false if neither of it is true
    if (interactive === undefined) {
        interactive = (enableSelection && selectOnRowClick) || onRowClick !== undefined;
    }

    const columnsWithDefaultsApplied = columnDefinitionDefaults ?
        tableColumns.map(columnDef => ({ ...columnDefinitionDefaults, ...columnDef }))
        : tableColumns;

    // Look to see if any of the columns support sorting and enable it automatically.
    const enableSorting = Boolean(columnsWithDefaultsApplied.find(column => column.sortable));

    const init = useRef(true);

    // Map filters in input filterValues param to the format understood by react table.
    // Wrap it in useMemo so that it is performant.
    const initFilters = React.useMemo(() => {
        return filterValues?.map((filter) => {
            return {
                id: filter.column,
                value: filter.value
            }
        })
    }, [filterValues]);

    const sortByRef = useRef(sortBy);
    const filtersRef = useRef(filterValues);

    const initialState: any = { 
        pageSize: defaultPageSize || pageSizeOptions[0],
        globalFilter: globalFilterValue || null,
        filters: initFilters || [],
        sortBy: sortBy,
    };

    // If selectedRows array was provided as input, then convert it to the map format that
    // react table uses for indicating which rows are selected. Do not pass selectedRowIds
    // param in initial state as undefined or empty array if there aren't any selected rows
    if (selectedRows && selectedRows.length > 0) {
        // When maxSelectionCount param was provided, enforce it just in case selectedRows count exceeded the max count
        let finalSelectedRows = (maxSelectionCount && selectedRows.length > maxSelectionCount) ? selectedRows.slice(0, maxSelectionCount) : selectedRows;
        initialState.selectedRowIds = finalSelectedRows.reduce((output, row) => {
            output[row] = true;
            return output
        }, {});
    };
    // If expandedRows array was provided as input, then convert it to the map format that
    // react table uses for indicating which rows are expanded by default. 
    if (expandedRows && expandedRows.length > 0) {
        if (expandOnlyOneRow) {
            initialState.expanded = {
                [expandedRows[0]]: true
            };
        } else {
            initialState.expanded = expandedRows.reduce((output, row) => {
                output[row] = true;
                return output
            }, {});
        }
    };

    useEffect(() => {
        setTableColumns(
            columns
                .filter((column) => selectedIds.includes(column.id))
                .sort((a, b) => selectedIds.indexOf(a.id) - selectedIds.indexOf(b.id))
            );
    }, [selectedIds]);

    // Some of the columns will have to be re-mapped to (a) provide default values and
    // (b) match the format that react table understands
    const mappedColumns = React.useMemo(() => columnsWithDefaultsApplied.map(({
        id,
        accessor,
        sortable = false,
        sortFunction = "alphanumeric",
        formatter,
        Cell = ({ value }) => value || "",
        showFilter = false,
        alwaysShowFilter = false,
        multiValueFilter = false,
        filterFunction = multiValueFilter ? defaultMultiValueFilter : FilterFunctions.fuzzyText,
        filterControl = DefaultColumnFilterControl,
        ...column
    }) => {
        return {
            ...column,
            id: id === undefined ? accessor : id,
            accessor: accessor === undefined ? id : accessor,
            defaultCanSort: sortable,
            // If sortExternally is true, then pass a method that will always not perform any sorting
            sortType: sortExternally ?
                (_a, _b, _column, desc) => { desc ? 1 : -1 } :
                (
                    typeof sortFunction === "string" ?
                    sortFunction :
                    (a, b, column, desc) => sortFunction(a.original, b.original, column, desc)
                ),
            Cell: formatter ? ({ row: { original }}) => formatter(original) : Cell,
            showFilter,
            alwaysShowFilter,
            filter: filterExternally ? rows => rows : filterFunction,
            Filter: filterControl,
            multiValueFilter,
            autoResetFilters: false,
        };
    }), [tableColumns, sortExternally, filterExternally]);

    // The current set of expanded rows or an empty array if no rows are expanded.
    const currentRowExpansion = useRef<Array<string>>([]);

    // Render the headless react table instance by calling useTable hook.
    // This hook will take in all the input params and all the additional behavior plugin hooks.
    // It will return an instance object from which we'll map the properties that are of interest to us.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        state,
        setFilter,
        preGlobalFilteredRows,
        setGlobalFilter,
        toggleAllRowsSelected,
        isAllRowsSelected,
        selectedFlatRows,
        isAllRowsExpanded,
        toggleAllRowsExpanded,
        state: { selectedRowIds },
        ...unmappedInstanceProps  //this is passing too much stuff to the pagination, but for now we don't need anything else
    } = useTable({
        columns: mappedColumns,
        data,
        initialState,
        globalFilter: globalFilterFunction,
        autoResetGlobalFilter: false,
        disableSortRemove: true, // Defaulting sortRemove property of react table's behavior to true. Override using useTableParams if needed.
        ...useTableParams,
    }, useGlobalFilter, useFilters, useSortBy, useExpanded, usePagination, useRowSelect);
    const {pageIndex, pageSize, globalFilter} = state;
    useEffect(() => {
        // If sortBy in state has changed from the last value
        if (onSortByChange && !isEqual(state.sortBy, sortByRef.current)) {
            onSortByChange(state.sortBy, sortByRef.current);
            sortByRef.current = state.sortBy;
        }
    }, [state.sortBy]);
    useEffect(() => {
        // If filters in state has changed from the last value
        if (onFiltersChange && !isEqual(state.filters, filtersRef.current)) {
            onFiltersChange(state.filters, filtersRef.current);
            filtersRef.current = state.filters;
        }
    }, [state.filters]);

    const pageCount = useRef<number>(0);
    if (unmappedInstanceProps.pageCount !== pageCount) {
        pageCount.current = unmappedInstanceProps.pageCount;
        if (onPageCountChange) {
            onPageCountChange(pageCount.current);
        }
    } 

    const displayedRows = enablePagination ? page : rows;

    if (onExpansionChange) {
        const expandedRowIds: Array<string> = [];
        for (const row of rows) {
            if (row.isExpanded) {
                expandedRowIds.push(row.id);
            }
        }
        if (currentRowExpansion.current.length !== 0 || expandedRowIds.length !== 0) {
            // We need to check the elements
            if (currentRowExpansion.current.length !== expandedRowIds.length) {
                currentRowExpansion.current = expandedRowIds;
            } else {
                for (const id of expandedRowIds) {
                    if (!currentRowExpansion.current.includes(id)) {
                        currentRowExpansion.current = expandedRowIds;
                        break;
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (onExpansionChange) {
            const newExpandedRows: Array<Row> = [];
            for (const row of rows) {
                if (row.isExpanded) {
                    newExpandedRows.push(row);
                }
            }
            onExpansionChange(newExpandedRows);
        }
    }, [currentRowExpansion.current]);

    // Identify if at least one of the displayed data rows has an expandable behavior
    const { hasExpandableRows, moreThanOneExpandableRows } = React.useMemo(() => {
        let hasExpandableRows = false,
            moreThanOneExpandableRows = false;
        for (const record of data) {
            // A row is expandable if it has subRows or a subComponent specified
            if (record.subRows || record.subComponent) {
                if (hasExpandableRows) {
                    moreThanOneExpandableRows = true;
                    break;
                } else {
                    hasExpandableRows = true;
                }
            }
        }
        return { hasExpandableRows, moreThanOneExpandableRows };
    }, [displayedRows]);
    
    // When selected rows list changes, fire the onSelectionChange callback if it was passed.
    // Memoize this logic so that it fires only when row selection changes
    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedFlatRows.map(row => {
                return {
                    id: row.id,
                    index: row.index,
                    ...row.original
                };
            }));
        }
    }, [selectedRowIds, selectedFlatRows.length]);

    // If globalFilterValue param changes, set the updated values if it is different from globalFilter in current table state
    useEffect(() => {
        if (!isEqual(globalFilter, globalFilterValue)) {
            setGlobalFilter(globalFilterValue);
        }
    }, [globalFilterValue]);

    //we debounce pageIndex to avoid calling the event too many times if the user is spamming the button
    const [pageIndexDebounced] = useDebouncedState(pageIndex);

    useEffect(() => {
        if (init.current) {
            return;
        }
        // When switching between pages, unselect all row selections if enableSelection flag was passed as true
        if (enableSelection && resetSelectionsOnPaginate) {
            toggleAllRowsSelected(false);
        }
        if (onPaginationChange) {
            onPaginationChange({
                pageIndex: pageIndexDebounced,
                pageSize: pageSize,
            });
        }
    }, [pageIndexDebounced, pageSize, onPaginationChange]);

    //this has to be the last effect, so all the effects are skipped on first init
    useEffect(() => {
        init.current = false;
    }, []);

    const handleClickOnTable = useCallback(e => {
        if (onClick) {
            onClick(e);
        }
        if (interactive && onRowClick) {
            const clickedElement = e.target;
            // Make sure the click happened on a data row and not on header row or 'No Data' row
            if (clickedElement?.closest("[role=rowgroup]") !== null) {
                const tableRow = clickedElement.closest("tr");
                if (tableRow) {
                    e.id = tableRow.getAttribute("data-row-id");
                    e.record = rows.find(row => row.id === e.id || row.original.id === e.id)?.original;
                }
                onRowClick(e);
            }
        }
    }, [onClick, onRowClick]);


    let allRowsSelected = true;
    /** When pagination is enabled, the checked status of the top checkbox is based on if all rows
     * IN THE CURRENT PAGE are checked or not. This is so that the users don't get confused in the UI
     */
    if (enablePagination) {
        if (page.length > 0) {
            for (const row of page) {
                if (!row.isSelected) {
                    allRowsSelected = false;
                    break;
                }
            }
        } else {
            // If the table is empty, set this flag to false
            allRowsSelected = false;
        }
    } else {
        allRowsSelected = isAllRowsSelected;
    }
    const wrappedToggleAllRowsSelected = (set?: Boolean) => {
        /** It would be preferrable to use toggleAllPageRowsSelected instead of row.toggleRowSelected.
         * But upon initial investigation, it seems like that method, while mentioned in react table docs,
         * isn't available as a part of the instance props. Until that is figured out, we'll keep using
         * the row.toggleRowSelected and manually selecting/deselecting all rows in the current active page */
        if (enablePagination) {
            page.forEach(row => {
                if (row.toggleRowSelected) {
                    row.toggleRowSelected(set);
                }
            });
        } else {
            toggleAllRowsSelected(set);
        }
    }

    const columnChooser = useRef();
    const handleOpen = () => {
        if (columnChooser.current) {
            // @ts-ignore
            columnChooser.current.handleOpen();
          }
    };

    const handleColumnsUpdate = (columnIds) => {
        setSelectedIds(columnIds);
        onColumnsChange && onColumnsChange(columnIds);
    };

    const createFlippedTable = () => {
        if (tableRef.current) {
            const table: HTMLTableElement | null = tableRef.current.querySelector("table");
            if (table) {
                const originalRows = table.rows;
                const flippedTable = document.createElement('table');
                const flippedBody = document.createElement('tbody');
                for (let i = 0; i < originalRows[0].cells.length; i++) {
                    const newRow = document.createElement('tr');
                    for (let j = 0; j < originalRows.length; j++) {
                        const newCell = document.createElement('td');
                        newCell.innerHTML = originalRows[j].cells[i].innerHTML;
                        newRow.appendChild(newCell);
                    }
                    flippedBody.appendChild(newRow);
                }
                flippedTable.id = table.id;
                flippedTable.className = table.className;
                flippedTable.appendChild(flippedBody);
                if (flipTableTarget.current) {
                    while (flipTableTarget.current.firstChild) { 
                        flipTableTarget.current.removeChild(flipTableTarget.current.firstChild); 
                    }
                    flipTableTarget.current.appendChild(flippedTable);
                }
            }
        }
    };

    const tableRef = useRef<HTMLDivElement | null>(null);
    const flipTableTarget = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!loading && flipTable) {
            setTimeout(() => {
                createFlippedTable();
            });
        }
    });

    return (
        <div id={id} className={className + " position-relative"}>
            {showColumnChooser && (
                <div className="settings-icon">
                    <Icon icon={IconNames.LIST_COLUMNS} onClick={handleOpen}/>
                </div>
            )}
            <div className={"tir-ui-table-container " + (removeOverflow ? "" : "overflow-auto") + (flipTable ? " d-none" : "")} ref={tableRef}>
                <LoadingOverlay visible={loading}/>
                {
                    (!enableTopPagination || enablePagination === false || hidePagination === true) ? undefined :
                    // Custom function ro render the top pagination
                    <Pagination
                        pageIndex={pageIndex} pageSize={pageSize} pageSizeOptions={pageSizeOptions}
                        onPageSizeChange={onPageSizeChange} onPageIndexChange={onPageIndexChange}
                        {...unmappedInstanceProps}
                    />
                }
                <HTMLTable
                    className={
                        "tir-ui-table display-8" +
                        (transparent ? " tir-ui-table-bg-transparent" : "") +
                        (columnsBordered ? " tir-ui-table-columns-bordered" : "") +
                        (enableSelection ? " tir-ui-table-selectable" : "")
                    }
                    bordered={bordered}
                    interactive={interactive}
                    onClick={handleClickOnTable}
                    striped={striped}
                    condensed={condensed}
                    {...passThroughIHTMLTableProps}
                >
                    <THead
                        headerGroups={headerGroups}
                        getHeaderProps={getHeaderProps}
                        getColumnProps={getColumnProps}
                        enableSorting={enableSorting}
                        showFilterRow={showFilterRow}
                        enableSelection={enableSelection}
                        showSelectionControl={showSelectionControl}
                        maxSelectionCount={maxSelectionCount}
                        toggleAllRowsSelected={wrappedToggleAllRowsSelected}
                        isAllRowsSelected={allRowsSelected}
                        onHeaderClick={onHeaderClick}
                        hasExpandableRows={hasExpandableRows}
                        showExpandAllControl={moreThanOneExpandableRows && !expandOnlyOneRow}
                        isAllRowsExpanded={isAllRowsExpanded}
                        toggleAllRowsExpanded={toggleAllRowsExpanded}
                    />
                    <TBody
                        data={data}
                        columnCount={columns.length}
                        noDataText={noDataText}
                        getTableBodyProps={getTableBodyProps}
                        rows={displayedRows}
                        prepareRow={prepareRow}
                        colorScheme={colorScheme}
                        enableSelection={enableSelection}
                        maxSelectionCount={maxSelectionCount}
                        showSelectionControl={showSelectionControl}
                        selectOnRowClick={selectOnRowClick}
                        selectedRowsCount={selectedFlatRows.length}
                        toggleAllRowsSelected={wrappedToggleAllRowsSelected}
                        getRowProps={getRowProps}
                        getColumnProps={getColumnProps}
                        getCellProps={getCellProps}
                        hasExpandableRows={hasExpandableRows}
                        expandOnlyOneRow={expandOnlyOneRow}
                        expandOnRowClick={expandOnRowClick}
                        toggleAllRowsExpanded={toggleAllRowsExpanded}
                    />
                </HTMLTable>
            </div>
            {flipTable && <div className="tir-ui-table-container flipped-table" ref={flipTableTarget}></div>}
            {
                enablePagination === false || hidePagination === true || enableTopPagination === true ? "" :
                    // Custom function to render the pagination
                    <Pagination
                        pageIndex={pageIndex} pageSize={pageSize} pageSizeOptions={pageSizeOptions}
                        onPageSizeChange={onPageSizeChange} onPageIndexChange={onPageIndexChange}
                        {...unmappedInstanceProps}
                    />
            }
            <ColumnChooser columns={columns} selectedIds={selectedIds} ref={columnChooser} handleColumnsUpdate={handleColumnsUpdate}></ColumnChooser>
        </div>
    );
};
