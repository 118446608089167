import axios from 'axios';
import { AuthProvider } from '../provider/AuthProvider';
import { UserAccount } from '../models/UserAccount';
import {
    AuthConfig,
    AuthConstants,
    PLATFORM_ROLES,
    ROLES_BY_PRODUCT,
    BruleeRolesType,
    GelatoRolesType,
    ProductType
} from "../constants/Constants";
import { STRINGS } from "../library_strings";
import { ITenant, TenantMapProps } from "../tenants";

type RoleType = BruleeRolesType | GelatoRolesType;

type TenantPerms = {
    tenant_id: string;
    permissions: RoleType[];
}

type ItemsResponse = {
    items: TenantPerms[];
}

type permissionsRes = {
    data: ItemsResponse;
}

export const findRoles = (rolesArray: string[], searchTerms: string[]): boolean => {
  return searchTerms.every(term => 
    rolesArray.some(role => role.includes(term))
  );
}

class AuthService {
    /* AuthProvider should be accessed via the instance contained here **/
    static authProvider: AuthProvider;
    /* AuthService is the interface for the tenant information **/
    private static tenant: ITenant;
    private static userAccount: UserAccount;
    static isEmbedded: boolean;

    static setUserAccount = (userAccount: UserAccount) => {
        AuthService.userAccount = userAccount;
    };

    static addRoles(roles: string[]) {
      if (AuthService.userAccount) {
        AuthService.userAccount.addRoles(roles);
      } else {
        console.error('UserAccount is not set. Cannot add roles.');
      }
    }

    static init(
        authConfig: { tenantServiceLocation: string, staticTenants?: TenantMapProps },
        forceTenant?: string,
        isEmbedded?: boolean
    ) {
        if (AuthService.tenant) {
            // block anybody from getting away with double initialization
            console.error('Initializing AuthService more than once!');
            return;
        }

        // set if auth component is embedded in an iframe
        this.isEmbedded = isEmbedded || false;

        //extract tenant from uri
        let tenantName = forceTenant || window.location.host.split('.')[0];

        const params = {name: tenantName};

        //fetch tenant information
        return axios.get(authConfig.tenantServiceLocation, {params: params}).then(
            (response) => {
                // TODO: decide standard way to handle empty collection, right now api returns 404 which is incorrect
                // always exists given previous comment
                AuthService.tenant = response.data.items[0];
                // initialize the authProvider now that we have the idp info
                AuthService.authProvider = new AuthProvider(
                    authConfig,
                    AuthService.tenant,
                    isEmbedded || false
                );

                //throw new Error("Test error");
                return Promise.resolve();
            },
            (err) => {
                console.error("Public Tenant API Error");
                if (authConfig.staticTenants && authConfig.staticTenants.hasOwnProperty(tenantName)) {
                    AuthService.tenant = authConfig.staticTenants[tenantName];
                    AuthService.authProvider = new AuthProvider(
                        authConfig,
                        AuthService.tenant,
                        isEmbedded || false
                    );
                    return Promise.resolve();
                } else {
                    console.error(`Static tenant information not found for ${tenantName}`);
                    return Promise.reject(err);
                }
            }
        );
    }

    static authorize(authConfig: { permissions: (userId: string) => string }, product?: string): Promise<boolean> {
      return new Promise((resolve, reject) => {
        AuthService.getToken().then(
          (accessToken) => {
            const userAccount = AuthService.authProvider.getUserAccount();
            AuthService.setUserAccount(
              new UserAccount(
                userAccount.username,
                userAccount.name || userAccount.username,
                userAccount.localAccountId
              )
            );

            const userId = AuthService.getUserAccount().getUserId();
            const perms = authConfig.permissions(userId);

            axios
              .get(perms, {
                headers: { Authorization: `Bearer ${accessToken}` },
              })
              .then((res: permissionsRes) => {
                const tenID = AuthService.tenant.tenant_id;
                const userPermissions = res.data.items.find(({tenant_id}) => tenant_id === tenID)
                const roles = userPermissions?.permissions as RoleType[] || [];
                AuthService.addRoles(roles);

                if (product && !roles) {
                  reject({
                    title: STRINGS.ERROR.authorizationError,
                    message: STRINGS.ERROR.no_product_access,
                  });
                } else {
                  resolve(true);
                }
              });
          },
          (error) => {
            reject(error);
          }
        );
      });
    }

    static logout() {
        AuthService.authProvider.logout();
    }

    static login() {
        if (this.isEmbedded) {
            AuthService.authProvider.loginPopup({
                scopes: AuthConfig.scopes,
                domainHint: AuthService.getTenant().idp
            });
        } else {
            AuthService.authProvider.loginRedirect({
                scopes: AuthConfig.scopes,
                domainHint: AuthService.getTenant().idp
            });
        }
    }

    static getUserAccount(): UserAccount {
        return AuthService.userAccount;
    }

    static getTenant() {
        return AuthService.tenant;
    }

    static getTenantId() {
        return AuthService.tenant.tenant_id;
    }

    static getToken(): Promise<string> {
        if (!AuthService.authProvider) {
            console.error(
                'Trying to get the auth token without first initializing the provider'
            );
        }
        return AuthService.authProvider.getToken();
    }

    static getAuthenticationState(): string {
        return AuthService.authProvider.getAuthenticationState();
    }

    static isAuthenticated(): boolean {
        return (
            AuthService.getAuthenticationState().toLowerCase() ===
            AuthConstants.AUTHENTICATED.toLowerCase()
        );
    }

    static isUserPortfolioAdmin(): boolean {
        const roles = AuthService.getUserAccount().getRoles();
        return roles.includes(PLATFORM_ROLES.PORTFOLIO_ADMIN);
    }

    static isUserAnyProductAdmin(): boolean {
      const roles = AuthService.getUserAccount().getRoles() as RoleType[];
      const hasAnyAdmin = findRoles(roles, ["admin"]);
      return hasAnyAdmin;
    }

    static isUserProductAdmin(product: string): boolean {
      const productName = product?.toLowerCase();
      const roles = AuthService.getUserAccount().getRoles() as RoleType[];
      const hasProductAdmin = findRoles(roles, ["admin", productName]);
      return hasProductAdmin;
    }

    static userHasProductRole(product: string): boolean {
      const productName = product?.toLowerCase();
      const roles = AuthService.getUserAccount().getRoles() as RoleType[];
      const hasProductRole = findRoles(roles, [productName]);
      return hasProductRole;
    }

    static isUserAuthorizedForProduct(product: string): boolean {
        return AuthService.isUserPortfolioAdmin()
            || AuthService.isUserProductAdmin(product)
            || AuthService.userHasProductRole(product);
    }

    static userHasWriteAccess(product: string): boolean {
      const roles = AuthService.getUserAccount().getRoles() as RoleType[];
      const hasWriteAccess = findRoles(roles, ["write"]);
        return AuthService.isUserPortfolioAdmin()
            || AuthService.isUserProductAdmin(product)
            || hasWriteAccess;
    }

    static getProductRoles(product: string): RoleType[] | [] {
      const upperCaseProduct = product.toUpperCase() as ProductType;
      if (upperCaseProduct in ROLES_BY_PRODUCT) {
        return Object.values(ROLES_BY_PRODUCT[upperCaseProduct]);
      } else {
        return [];
      }
    }
}

export { AuthService };
